<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="EventController"
        add-route-name="quick-event-add"
        text="Add Event"
      />
    </div>

    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <search
            placeholder="Title"
            :search-query.sync="searchQuery.title"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col md="6">
          <date-range-filter :date-range.sync="searchQuery.dateRange" />
        </b-col>

        <b-col
          md="10"
          class="mb-1 mb-md-0"
        >
          <b-form-checkbox
            id="is-fundraising"
            v-model="searchQuery.isFundraisingEvent"
            :value="true"
            :unchecked-value="false"
          >
            Filter By Fundraising Events
          </b-form-checkbox>
        </b-col>

        <b-col
          md="2"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <!-- Spinner -->
    <spinner-loader :loading="loading" />

    <!-- Table -->
    <b-card>
      <b-table
        id="table"
        ref="table"
        primary-key="id"
        :no-provider-sorting="true"
        :items="getQuickEvents"
        :fields="tableColumns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        show-empty
        responsive
        empty-text="No events found"
      >
        <template #cell(actions)="{ item }">
          <b-dropdown
            id="download-prayers-options"
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'quick-event-view', params:{ id: item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <template v-if="item.fundraisingProject && $can('approve','FundraisingController')">
              <b-dropdown-item @click="startProject(item.fundraisingProject.id)">
                <feather-icon icon="PlayIcon" />
                <span> Start </span>
              </b-dropdown-item>

              <b-dropdown-item @click="stopProject(item.fundraisingProject.id)">
                <feather-icon icon="StopCircleIcon" />
                <span> Stop </span>
              </b-dropdown-item>
            </template>

            <b-dropdown-item
              v-if="!item.singleSession"
              :to="{ name: 'quick-event-sessions-list', params:{ eventId: item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Sessions</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="exportEvent(item.id)"
            >
              <feather-icon icon="ShareIcon" />
              <span class="align-middle ml-50">Export</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update','EventController')"
              :to="{ name: 'quick-event-edit', params:{ id: item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete','EventController')"
              @click="confirmOperation(deleteEndPoint,item.id)"
            >
              <feather-icon
                icon="TrashIcon"
                class="text-danger"
              />
              <span class="align-middle ml-50 text-danger">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import deleteEvent from '@/common/compositions/common/deleteRecord'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import ProjectStatus from '@/common/compositions/Fundraising/ProjectStatus'
import ProjectActiveStatus from '@/common/compositions/Fundraising/ProjectActive'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'QuickEventList',
  components: {
    AddResource,
    Pagination,
    SpinnerLoader,
    DateRangeFilter,
    Search,
    SearchButton,
  },
  data() {
    return {
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/events/`,
      loading: true,
      searchQuery: { title: '', dateRange: [null, null], isFundraisingEvent: false },
      isThereBookings: false,
    }
  },
  computed: {
    statusOptions() {
      return ['Drafted', 'Send for approval', 'Approved']
    },
    tableColumns() {
      const columns = [
        { key: 'id' },
        { key: 'name', label: 'event title' },
        { key: 'sessions_count' },
        { key: 'startTime' },
        { key: 'fundraisingProject', label: 'event type', formatter: v => (v ? 'Fundraising' : 'Event') },
        { key: 'singleSession', label: 'session type', formatter: v => (v ? 'Single Session' : 'Multi Session') },
        { key: 'endTime' },
      ]

      const quickEventsColumns = [
        ...columns,
        { key: 'actions' },
      ]

      const fundraisingEvent = [
        ...columns,
        { key: 'fundraisingProject.title', label: 'fundraising title' },
        { key: 'fundraisingProject.to_be_raised', label: 'Target' },
        { key: 'fundraisingProject.start_time', label: 'start date time' },
        { key: 'fundraisingProject.end_time', label: 'end date time' },
        { key: 'fundraisingProject.status', label: 'fundraising status', formatter: v => this.statusOptions[v] },
        { key: 'fundraisingProject.zakat_verified', label: 'zakat verified', formatter: v => (v ? 'Yes' : 'No') },
        { key: 'actions' },
      ]

      return this.searchQuery.isFundraisingEvent ? fundraisingEvent : quickEventsColumns
    },
    deleteMessage() {
      return this.isThereBookings ? 'Event has active booked users, Do you need to delete' : null
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    const { deleteRecord, table } = deleteEvent()
    const { startProject, stopProject } = ProjectStatus()
    const { toggleProject } = ProjectActiveStatus()
    return {
      toggleProject,
      startProject,
      stopProject,
      deleteRecord,
      table,
      successfulOperationAlert,
      pagination,
    }
  },
  methods: {
    getQuickEvents() {
      return this.$activities.get(`/internalops/events/entity/${this.$store.getters['mainEntity/getEntityId']}`, {
        params: {
          page: this.pagination.currentPage,
          title: this.searchQuery.title,
          from_date: this.searchQuery.dateRange[0],
          to_date: this.searchQuery.dateRange[1],
          fundraising: this.searchQuery.isFundraisingEvent,
        },
      }).then(res => {
        const quickEvents = res.data.data

        this.pagination.totalRows = res.data.pagination.total
        return quickEvents || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    exportEvent(id) {
      return this.$activities.post(`/internalops/events/${id}/export/excel`, {}, {
        responseType: 'blob',
      }).then(res => {
        this.successfulOperationAlert('Event is exported successfully')

        const blob = new Blob([res.data])
        saveAs(blob, `${new Date()}_Quick_Event.csv`)
      })
    },
    confirmOperation(endPoint, id) {
      this.$activities.get(`/internalops/events/${id}}/bookings-check`).then(res => {
        this.isThereBookings = res.data.data.hasBookings
        this.deleteRecord(endPoint, id, this.deleteMessage)
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss">
</style>
