import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '../common/handleAlerts'

export default function ProjectActiveStatus() {
  const { successfulOperationAlert } = handleAlerts()

  const toggleProject = item => {
    axiosInstances.activities.post(`/internalops/fundraising/project/${item.id}/toggle/active`).then(res => {
      item.active = !item.active
      const projectStatus = res.data.data.active ? 'resumed' : 'paused'
      successfulOperationAlert(`Project is ${projectStatus} successfully`)
    })
  }
  return { toggleProject }
}
